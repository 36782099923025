<template>
  <v-autocomplete
    v-model="selectedItems"
    :items="tags"
    :label="$trans('tag')"
    :multiple="multiple"
    :small-chips="smallChips"
    item-text="name"
    item-value="id"
    :dense="dense"
    :no-data-text="$trans('nothing_found_here')"
    outlined
    :height="selectedItems && selectedItems.length > 1 ? null : height"
    :disabled="disabled"
    hide-details
    clearable
    :deletable-chips="deletableChips"
    :attach="'#' + uniqueId"
    :menu-props="{ offsetY: true }"
    @change="$emit('input', selectedItems)"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="chips || smallChips">
          <v-chip
            :small="smallChips"
            :disabled="disabled"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <span class="text-truncate">{{ data.item.name }}</span>
          </v-chip>
        </template>
        <template v-else>
          <span class="text-truncate">{{ data.item.name }}</span>
        </template>
      </div>
    </template>

    <template #item="data">
      <div class="d-flex align-center pointer my-2">
        <span class="text-break cd-v-select-line-height">{{
          data.item.name
        }}</span>
      </div>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>
      <slot />
      <add-tag-modal v-if="!hideActionButtons && loggedUserCanAddTags" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import AddTagModal from "@/views/dashboard/pages/Calendar/components/AddTagModal";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedField from "@/calendesk/mixins/sharedField";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";

export default {
  name: "TagsField",
  components: { AddTagModal },
  mixins: [sharedField, roleActions, sharedFieldActions],
  computed: {
    ...mapGetters({
      tags: "tag/tags",
      newTag: "tag/getNewTag",
    }),
  },
  watch: {
    newTag(val) {
      if (val && !this.hideActionButtons) {
        this.selectedItems.push(val.id);
        this.$emit("tagAdded");
      }
    },
  },
};
</script>
