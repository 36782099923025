<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
      unavailable_booking_slot_details:
        item.event_type === eventTypes.unavailableBookingSlot,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          v-if="
            item.event_type === eventTypes.booking ||
            item.event_type === eventTypes.unavailableBookingSlot
          "
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
        <v-simple-checkbox
          v-else-if="item.event_type === eventTypes.groupBooking"
          :ripple="false"
          :value="isGroupSelected"
          :indeterminate="isGroupIndeterminate"
          color="primary"
          @input="toggleGroupSelection(item)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
        'v-align-middle': item.event_type === eventTypes.groupBooking,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div v-if="item.event_type !== eventTypes.groupBooking">
          {{ $trans("id") }}
        </div>
        <div v-else>
          {{ $trans("group_booking_title") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-if="item.event_data.id" class="text-left">
          <div>{{ item.event_data.id }}</div>
        </div>
        <div
          v-else-if="
            $vuetify.breakpoint.mdAndUp &&
            item.event_type === eventTypes.groupBooking
          "
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="blue-light"
                class="mb-2"
                fab
                x-small
                elevation="0"
                dark
                @click.stop="clickRow"
                v-on="on"
              >
                <v-icon color="blue">
                  {{ isExpanded ? "$chevron-up" : "$chevron-down" }}
                </v-icon>
              </v-btn>
            </template>
            <span v-if="isExpanded">{{ $trans("collapse") }}</span>
            <span v-else>{{ $trans("expand") }}</span>
          </v-tooltip>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("date") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-html="calendarDate(item)" />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("event_type") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="
            (item.event_type === eventTypes.booking ||
              item.event_type === eventTypes.groupBooking) &&
            item.event_data.service_id
          "
        >
          <event-service
            hide-duration
            clickable
            push-transition
            :justify-left="$vuetify.breakpoint.mdAndUp"
            :justify-right="!$vuetify.breakpoint.mdAndUp"
            :service-id="item.event_data.service_id"
            :service-type-id="
              item.event_data.service_type_id
                ? item.event_data.service_type_id
                : null
            "
            :products="
              item.event_data.simple_store_products &&
              item.event_data.simple_store_products.length > 0
                ? item.event_data.simple_store_products
                : []
            "
          />
          <div v-if="item.event_type === eventTypes.booking">
            <div
              class="d-flex align-center flex-wrap py-2"
              :class="{
                'justify-start': $vuetify.breakpoint.mdAndUp,
                'justify-end': !$vuetify.breakpoint.mdAndUp,
              }"
            >
              <event-status-chips
                :parsed-event="item.event_data"
                :class="{
                  'mr-1': item.event_data.payment_transaction,
                }"
              />
              <small v-if="item.event_data.payment_transaction">
                ({{ getPaidAmount(item.event_data) | money }},
                {{
                  $trans(item.event_data.payment_transaction.provider_tag)
                }})</small
              >
            </div>
          </div>
        </div>
        <div
          v-if="item.event_type === eventTypes.unavailableBookingSlot"
          class="d-flex"
        >
          <event-source-type :item="item" class="mr-2" />
          <span
            v-if="item.event_data && item.event_data.description"
            class="text-wrap text-break"
            >{{ item.event_data.description | truncate(300) }}</span
          >
          <span v-else>{{ $trans("blackout_date_title") }}</span>
        </div>
      </div>
    </td>
    <td
      v-if="
        $vuetify.breakpoint.mdAndUp ||
        (item.event_type !== eventTypes.groupBooking &&
          item.event_type !== eventTypes.unavailableBookingSlot)
      "
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("client") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="item.event_type === eventTypes.booking && item.event_data.user"
        >
          <user-card-details
            details-in-tooltip
            justify-left
            clickable
            push-transition
            :user="item.event_data.user"
          />
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("employee") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="
            item.event_data.employee_id ||
            item.event_type === eventTypes.unavailableBookingSlot
          "
          class="employee-details"
        >
          <div class="mb-2">
            <employee-card-details
              v-if="item.event_data.employee_id"
              details-in-tooltip
              justify-left
              clickable
              push-transition
              :employee-id="item.event_data.employee_id"
            />
            <div
              v-else-if="item.event_type === eventTypes.unavailableBookingSlot"
            >
              {{ $trans("all_employees") }}
            </div>
          </div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <event-list-buttons :is-hovered="isHovered" :item="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import UserCardDetails from "@/components/UserCardDetails.vue";
import EmployeeCardDetails from "@/components/EmployeeCardDetails.vue";
import EventService from "@/components/Event/EventService.vue";
import EventListButtons from "@/views/dashboard/pages/Sales/Events/components/EventList/EventListButtons.vue";
import EventStatusChips from "@/components/Event/EventStatusChips.vue";
import eventItemRow from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventItemRow";
import EventSourceType from "@/views/dashboard/pages/Sales/Events/components/EventList/EventSourceType.vue";

export default {
  components: {
    EventSourceType,
    EventStatusChips,
    EventListButtons,
    EventService,
    EmployeeCardDetails,
    UserCardDetails,
  },
  mixins: [eventItemRow],
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isGroupSelected: {
      type: Boolean,
      default: false,
    },
    isGroupIndeterminate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleGroupSelection(item) {
      if (this.isGroupSelected) {
        this.$emit("deselectGroupBookings", item);
      } else {
        this.$emit("selectGroupBookings", item);
      }
    },
    selectOnChange(event) {
      this.$emit("selectOnChange", {
        id: this.item.event_data.id,
        type: this.item.event_type,
        item: this.$helpers.cloneObject(this.item),
        event,
      });
    },
  },
};
</script>
