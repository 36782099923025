<template>
  <div>
    <event-list />
  </div>
</template>

<script>
import EventList from "@/views/dashboard/pages/Sales/Events/components/EventList.vue";

export default {
  name: "CalendeskEvents",
  components: { EventList },
};
</script>
