<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="reducedSelectedItems"
    :search-input.sync="searchText"
    no-filter
    outlined
    :items="allItems"
    :label="
      !selectedItems || (multipleSelection && selectedItems.length === 0)
        ? label || $trans('service')
        : label || $trans('service')
    "
    :multiple="multipleSelection"
    hide-details="auto"
    item-text="display_name"
    :dense="dense"
    :rules="rules"
    :loading="isLoading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_here')"
    :placeholder="$trans('autocomplete_hint')"
    return-object
    :clearable="clearable"
    :attach="'#' + uniqueId"
    class="cd-fake-outer"
    @input="onInputChange"
    @blur="handleInputBlur"
  >
    <template #append-item>
      <v-list-item v-if="canLoadMorePages" v-intersect="onIntersect">
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center justify-center pointer my-2">
              <span class="text-break cd-v-select-line-height text-wrap">
                {{ $trans("loading") }}
              </span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="data.index < maxItemsInSelectField">
          <v-chip
            v-if="chips || smallChips"
            :disabled="disabled"
            :small="smallChips"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <v-auto-complete-chips-tooltip>
              <service-card-details
                v-if="!data.item.is_category"
                :service="getServiceForCardDetails(data.item)"
                get-service-type-from-service-object
              />
              <div v-else>
                {{ data.item.display_name }}
              </div>
            </v-auto-complete-chips-tooltip>
            <v-avatar
              v-if="data.item.default_image"
              :size="32"
              left
              class="mr-2"
            >
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-truncate">{{ data.item.display_name }}</span>
          </v-chip>
          <template v-else>
            <v-avatar
              v-if="data.item.default_image"
              :size="32"
              left
              class="mr-2"
            >
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-truncate">{{ data.item.display_name }}</span>
          </template>
        </template>
        <div
          v-if="multipleSelection && data.index === maxItemsInSelectField"
          class="grey--text text-caption"
        >
          (+{{ selectedItems.length - maxItemsInSelectField }}
          {{ $trans("more") }})
        </div>
      </div>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        v-bind="attrs"
        :class="{
          'primary--text v-list-item--active v-list-item--highlighted':
            isItemSelected(item),
        }"
        v-on="on"
        @click.stop="toggleItem(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center pointer my-2">
              <v-avatar v-if="item.default_image" :size="32" left class="mr-2">
                <v-img :src="$helpers.getAvatarSrc(item)" />
              </v-avatar>
              <span
                class="text-break cd-v-select-line-height text-wrap"
                :class="{
                  'force-header': item.is_category,
                }"
              >
                {{ item.display_name }}
              </span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from "vuex";
import VAutoCompleteChipsTooltip from "@/lib/calendesk-js-library/components/VAutoCompleteChipsTooltip.vue";
import ServiceCardDetails from "@/lib/calendesk-js-library/components/service/ServiceCardDetails.vue";
import paginatedSelectInput from "@/lib/calendesk-js-library/mixins/paginatedSelectInput";

export default {
  name: "ServicesField2",
  components: { VAutoCompleteChipsTooltip, ServiceCardDetails },
  mixins: [paginatedSelectInput],
  props: {
    showOnlyGroupServices: {
      type: Boolean,
      default: false,
    },
    showOnlyOneToOneServices: {
      type: Boolean,
      default: false,
    },
    allowCategorySelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      action: "searchForServices",
    };
  },
  methods: {
    ...mapActions({
      searchForServices: "service/search",
    }),
    parseResponse(newItems) {
      let mergedItems = [];
      if (!this.searchText) {
        // Filter out existing headers and dividers
        let existingItems = this.allItems.filter(
          (item) => !item.header && !item.divider
        );

        newItems = Array.isArray(newItems) ? newItems : [];

        // Merge new items from the server response
        mergedItems = [...existingItems, ...newItems];

        // Merge selected items (then might not be returned in the response)
        if (
          this.multipleSelection &&
          this.selectedItems &&
          this.selectedItems.length > 0
        ) {
          mergedItems.push(...this.selectedItems);
        } else if (!this.multipleSelection && this.selectedItem) {
          mergedItems.push(this.selectedItem);
        }

        this.sortItems(mergedItems);
      } else {
        let selectedItems = [];

        if (
          this.multipleSelection &&
          this.selectedItems &&
          this.selectedItems.length > 0
        ) {
          selectedItems.push(...this.selectedItems);
        } else if (!this.multipleSelection && this.selectedItem) {
          selectedItems.push(this.selectedItem);
        }

        this.sortItems(selectedItems);

        mergedItems = [...newItems, ...selectedItems];
      }

      // Use a Set to track processed categories
      const processedCategories = new Set();
      const orderedResult = [];

      mergedItems.forEach((item) => {
        // Add category header if this category hasn't been processed yet
        if (!processedCategories.has(item.category_id)) {
          processedCategories.add(item.category_id);

          let categoryData = {
            header: item.category_name,
            id: item.category_id,
          };

          if (this.allowCategorySelection) {
            categoryData = {
              is_category: true,
              unique_id: "local_cat_" + item.category_id,
              category_id: item.category_id,
              display_name: item.category_name,
              category_name: item.category_name,
              category_order_position: item.category_order_position,
              service_order_position: 0,
            };
          }

          orderedResult.push(categoryData);
        }

        if (item.service_id || item.service_type_id) {
          orderedResult.push(item);
        }
      });

      this.allItems = orderedResult;
    },
    sortItems(items) {
      items.sort((a, b) => {
        if (a.category_order_position !== b.category_order_position) {
          return (
            parseFloat(a.category_order_position) -
            parseFloat(b.category_order_position)
          );
        }
        if (a.service_order_position !== b.service_order_position) {
          return (
            parseFloat(a.service_order_position) -
            parseFloat(b.service_order_position)
          );
        }
        return (a.service_type_id || 0) - (b.service_type_id || 0);
      });
    },
    findItemIndex(list, item) {
      return list.findIndex(
        (listItem) => listItem.unique_id === item.unique_id
      );
    },
    areItemsEqual(item1, item2) {
      return item1.unique_id === item2.unique_id;
    },
    getServiceForCardDetails(item) {
      const result = {
        id: item.service_id,
        default_image: item.default_image,
        duration: item.service_duration,
        name: item.service_name,
        price: item.service_price,
        status: item.service_status,
      };

      if (item.service_type_id) {
        result.serviceType = {
          id: item.service_type_id,
          duration: item.service_type_duration,
          name: item.service_type_name,
          price: item.service_type_price,
        };
      } else {
        result.serviceType = null;
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.force-header {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>
