<template>
  <div>
    <v-tooltip v-if="item.event_data && item.event_data.is_google_event" bottom>
      <template #activator="{ on }">
        <v-img
          max-height="20"
          max-width="20"
          :src="require(`@/assets/google-calendar.png`)"
          v-on="on"
        />
      </template>
      {{ $trans("google_calendar") }}
    </v-tooltip>
    <v-tooltip
      v-else-if="item.event_data && item.event_data.is_outlook_event"
      bottom
    >
      <template #activator="{ on }">
        <v-img
          max-height="20"
          max-width="20"
          :src="require(`@/assets/outlook-logo.png`)"
          v-on="on"
        />
      </template>
      {{ $trans("microsoft_outlook") }}
    </v-tooltip>
    <v-tooltip v-else bottom>
      <template #activator="{ on }">
        <v-img
          max-height="20"
          max-width="20"
          :src="
            require(`@/lib/calendesk-js-library/assets/calendesk-logo-square.jpg`)
          "
          v-on="on"
        />
      </template>
      {{ $trans("calendesk") }}
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
