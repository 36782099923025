<template>
  <div v-if="!isSending" class="mb-16">
    <template v-if="bookingsExist || unavailableBookingSlotsExist">
      <event-filter
        :filtered-user="filteredUser"
        @filtersChanged="filtersChanged"
        @filtersRestored="filtersRestored"
      />
      <div>
        <v-data-table
          v-model="selected"
          :footer-props="footerProps"
          :headers="headers"
          :items="items"
          :loading="isLoadingTable"
          :loading-text="$trans('loading')"
          :no-data-text="$trans('nothing_found_here')"
          :no-results-text="$trans('nothing_found_here')"
          :options.sync="tableOptions"
          :server-items-length="total"
          :show-select="!noData"
          class="calendesk-datatable"
          :expanded="$vuetify.breakpoint.mdAndUp ? expanded : []"
          mobile-breakpoint="960"
        >
          <template #[`header.data-table-select`]>
            <div class="text-center">
              <v-simple-checkbox
                v-model="selectAllState"
                :ripple="false"
                :indeterminate="isIndeterminateForSelectAll"
                color="primary"
                @input="selectAllItemsOnChange"
              />
            </div>
          </template>

          <template #expanded-item="{ item }">
            <template v-if="item.event_data && item.event_data.bookings">
              <expanded-event-item-row
                v-for="(booking, index) in item.event_data.bookings"
                :key="'booking_details_' + booking.id"
                :index="index"
                :booking="booking"
                :item="item"
                :is-selected="
                  isRowSelected({
                    id: booking.id,
                    type: eventTypes.booking,
                  })
                "
                @reload="loadEventsDebounce"
                @selectOnChange="itemRowEventOnSelectChange"
                @clickRow="clickRow"
              />
            </template>
          </template>

          <template #[`item`]="{ item }">
            <event-item-row
              :is-expanded="isExpanded(item)"
              :item="item"
              :is-group-indeterminate="isGroupIndeterminate(item)"
              :is-group-selected="isGroupSelected(item)"
              :is-selected="
                isRowSelected({
                  id: item.event_data.id,
                  type: item.event_type,
                })
              "
              @deselectGroupBookings="deselectGroupBookings"
              @selectGroupBookings="selectGroupBookings"
              @selectOnChange="itemRowEventOnSelectChange"
              @clickRow="clickRow"
            />
          </template>
        </v-data-table>
        <selected-events-footer-menu
          :selected="selected"
          @update="handleSelectedBookingsUpdate"
        />
      </div>
    </template>
    <no-data-info
      v-else
      :button-text="$trans('add_booking')"
      :description="$trans('no_bookings_info')"
      @click="handleNoDataClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SelectedEventsFooterMenu from "@/views/dashboard/pages/Sales/Events/components/SelectedEventsFooterMenu.vue";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import statsActions from "@/calendesk/mixins/statsActions";
import planActions from "@/calendesk/mixins/planActions";
import EventItemRow from "@/views/dashboard/pages/Sales/Events/components/EventList/EventItemRow.vue";
import ExpandedEventItemRow from "@/views/dashboard/pages/Sales/Events/components/EventList/ExpandedEventItemRow.vue";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";
import EventFilter from "@/views/dashboard/pages/Sales/Events/components/EventFilter.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import cacheActions from "@/calendesk/mixins/cacheActions";

export default {
  name: "EventList",
  components: {
    EventFilter,
    ExpandedEventItemRow,
    EventItemRow,
    NoDataInfo,
    SelectedEventsFooterMenu,
  },
  mixins: [sharedList, statsActions, planActions, eventActions, cacheActions],
  props: {
    filteredUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cacheId: this.filteredUser ? "event-list-user" : "event-list",
      defaultSortBy: "starts_at",
      tableOptions: {
        itemsPerPage: 25,
        sortBy: ["starts_at"],
        sortDesc: [true],
        page: 1,
      },
      requestActionName: "fetchEvents",
      statsMode: this.$helpers.statsMode.bookings,
      expanded: [],
      selectedRows: [],
      headers: [
        {
          text: this.$trans("id"),
          value: "created_at",
          class: "header-style",
        },
        {
          text: this.$trans("date"),
          value: "starts_at",
          class: "header-style",
        },
        {
          text: this.$trans("event_type"),
          value: "event_type",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("client"),
          value: "client",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("employee"),
          value: "employee",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "menu",
          sortable: false,
          class: "header-style",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      shouldRefreshBookings: "booking/shouldRefreshBookings",
      shouldRefreshUnavailableBookingsSlotsStatus:
        "booking/shouldRefreshUnavailableBookingsSlotsStatus",
    }),
    countAllItems() {
      let number = 0;

      this.items.forEach((item) => {
        if (item.event_type === this.eventTypes.groupBooking) {
          number = number + item.event_data.bookings.length;
        } else {
          number = number + 1;
        }
      });

      return number;
    },
  },
  watch: {
    "getDialog.open": function () {
      if (this.getDialog.data && this.getDialog.data.reload === true) {
        this.forceReload();
      }
    },
    shouldRefreshBookings(val) {
      if (val) {
        if (!this.bookingsExist || !this.unavailableBookingSlotsExist) {
          this.fetchStats();
        }

        this.forceReload();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchEvents: "booking/fetchEvents",
    }),
    clickRow(item) {
      if (
        this.$vuetify.breakpoint.mdAndUp &&
        item.event_type === this.eventTypes.groupBooking
      ) {
        if (this.isExpanded(item)) {
          const indexExpanded = this.expanded.findIndex(
            (i) => i.id === item.id
          );
          this.expanded.splice(indexExpanded, 1);
        } else {
          const cloned = this.$helpers.cloneObject(item);
          this.expanded.push(cloned);
        }

        this.setCacheTableExpandedData(
          {
            data: this.expanded,
          },
          this.cacheId
        );
      }
    },
    isExpanded(item) {
      return this.expanded.some((expandedItem) => expandedItem.id === item.id);
    },
    handleNoDataClick() {
      this.$root.$emit("openEventModal");
    },
    handleSelectedBookingsUpdate() {
      this.selected = [];
      this.loadEventsDebounce();
    },
    getQueryOptions() {
      // Call the common parts in mixins
      let queryOptions = {};
      if (
        this.$options.mixins[0] &&
        this.$options.mixins[0].methods.getQueryOptions
      ) {
        queryOptions =
          this.$options.mixins[0].methods.getQueryOptions.call(this);
      }

      queryOptions.with = "bpt,prods,ppt,usr,up,ua,ui";
      return queryOptions;
    },
    itemRowEventOnSelectChange(selectable) {
      if (!this.isRowSelected(selectable)) {
        this.selected.push({
          ...selectable,
          page: this.tableOptions.page,
        });
      } else {
        this.selected = this.selected.filter(
          (obj) => obj.id !== selectable.id || obj.type !== selectable.type
        );
        this.selectAllState = false;
      }
    },
    isRowSelected(selectable) {
      return !!this.selected.find(
        (obj) => obj.id === selectable.id && obj.type === selectable.type
      );
    },
    selectAllItemsOnChange() {
      if (this.selectAllState && this.items) {
        this.items.forEach((item) => {
          if (
            item.event_type === this.eventTypes.booking ||
            item.event_type === this.eventTypes.unavailableBookingSlot
          ) {
            const newItem = {
              id: item.event_data.id,
              type: item.event_type,
              item,
              page: this.tableOptions.page,
            };

            if (!this.isRowSelected(newItem)) {
              this.selected.push(newItem);
            }
          } else if (item.event_data && item.event_data.bookings) {
            item.event_data.bookings.forEach((booking) => {
              const newItem = {
                id: booking.id,
                type: this.eventTypes.booking,
                item,
                page: this.tableOptions.page,
              };

              if (!this.isRowSelected(newItem)) {
                this.selected.push(newItem);
              }
            });
          }
        });
      } else {
        this.selected = this.selected.filter(
          (item) => item.page !== this.currentResponsePage
        );
      }
    },
    isGroupSelected(item) {
      if (item.event_type === this.eventTypes.groupBooking) {
        const selectedBookings = this.selected.filter(
          (obj) =>
            obj.type === this.eventTypes.booking && obj.item.id === item.id
        );
        return selectedBookings.length === item.event_data.bookings.length;
      }

      return false;
    },
    isGroupIndeterminate(item) {
      if (item.event_type === this.eventTypes.groupBooking) {
        const selectedBookings = this.selected.filter(
          (obj) =>
            obj.type === this.eventTypes.booking && obj.item.id === item.id
        );
        return (
          selectedBookings.length > 0 &&
          selectedBookings.length < item.event_data.bookings.length
        );
      }

      return false;
    },
    selectGroupBookings(item) {
      const newSelected = item.event_data.bookings.map((booking) => ({
        id: booking.id,
        type: this.eventTypes.booking,
        item,
        page: this.tableOptions.page,
      }));
      this.selected.push(...newSelected);
    },
    deselectGroupBookings(item) {
      this.selectAllState = false;
      this.selected = this.selected.filter(
        (obj) => obj.type !== this.eventTypes.booking || obj.item.id !== item.id
      );
    },
  },
};
</script>
