<template>
  <tr
    class="table-row group_booking_slot_details"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("booking_from_group") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          :class="{ 'pl-4': $vuetify.breakpoint.mdAndUp }"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        class="fill-height"
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="table_row-line-bg-wrapper"
          :class="{
            'table_row-last-item':
              index === item.event_data.bookings.length - 1,
            'text-center': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <div class="table_row-line-bg"></div>
          <img
            :src="require(`@/assets/circle.svg`)"
            height="16px"
            class="table_row-line-image"
          />
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("id") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="py-2">
          <span v-if="$vuetify.breakpoint.mdAndUp">{{ $trans("id") }}:</span>
          {{ booking.id }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("status") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
        class="d-flex align-center flex-wrap"
      >
        <event-status-chips
          :parsed-event="booking"
          :class="{
            'mr-1': booking.payment_transaction,
          }"
        />
        <small v-if="booking.payment_transaction">
          ({{ getPaidAmount(booking) | money }},
          {{ $trans(booking.payment_transaction.provider_tag) }})
        </small>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("client") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <user-card-details
          details-in-tooltip
          justify-left
          clickable
          push-transition
          :user="booking.user"
        />
      </div>
    </td>
    <td
      colspan="2"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div class="row-list-buttons white rounded mt-1">
        <event-list-buttons :is-hovered="isHovered" :item="getBooking" />
      </div>
    </td>
  </tr>
</template>
<script>
import EventListButtons from "@/views/dashboard/pages/Sales/Events/components/EventList/EventListButtons.vue";
import EventStatusChips from "@/components/Event/EventStatusChips.vue";
import eventItemRow from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventItemRow";
import UserCardDetails from "@/components/UserCardDetails.vue";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";

export default {
  components: {
    UserCardDetails,
    EventStatusChips,
    EventListButtons,
  },
  mixins: [eventItemRow, eventActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getBooking() {
      const eventData = this.item.event_data;

      return {
        ...this.item,
        id: this.booking.id,
        event_data: {
          ...this.booking,
          service: eventData.service ? { ...eventData.service } : null,
          employee: eventData.employee ? { ...eventData.employee } : null,
          service_type: eventData.service_type
            ? { ...eventData.service_type }
            : null,
        },
        event_type: this.eventTypes.booking,
      };
    },
  },
  methods: {
    selectOnChange(event) {
      this.$emit("selectOnChange", {
        id: this.booking.id,
        type: this.eventTypes.booking,
        item: this.$helpers.cloneObject(this.item),
        event,
      });
    },
  },
};
</script>
