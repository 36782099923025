var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"table-row group_booking_slot_details",on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("booking_from_group"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{class:{ 'pl-4': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{staticClass:"fill-height",class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"table_row-line-bg-wrapper",class:{
          'table_row-last-item':
            _vm.index === _vm.item.event_data.bookings.length - 1,
          'text-center': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('div',{staticClass:"table_row-line-bg"}),_c('img',{staticClass:"table_row-line-image",attrs:{"src":require("@/assets/circle.svg"),"height":"16px"}})]):_vm._e()])]),_c('td',{class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"py-2"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(_vm._s(_vm.$trans("id"))+":")]):_vm._e(),_vm._v(" "+_vm._s(_vm.booking.id)+" ")])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("status"))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center flex-wrap",class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('event-status-chips',{class:{
          'mr-1': _vm.booking.payment_transaction,
        },attrs:{"parsed-event":_vm.booking}}),(_vm.booking.payment_transaction)?_c('small',[_vm._v(" ("+_vm._s(_vm._f("money")(_vm.getPaidAmount(_vm.booking)))+", "+_vm._s(_vm.$trans(_vm.booking.payment_transaction.provider_tag))+") ")]):_vm._e()],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("client"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('user-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","user":_vm.booking.user}})],1)]),_c('td',{class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    },attrs:{"colspan":"2"}},[_c('div',{staticClass:"row-list-buttons white rounded mt-1"},[_c('event-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"item":_vm.getBooking}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }