<template>
  <v-card v-if="selected.length" flat class="selected-booking-menu">
    <v-card-text class="pt-0 pl-3">
      <div>
        <v-menu
          top
          offset-x
          close-on-click
          close-on-content-click
          min-width="0"
        >
          <template #activator="{ on }">
            <v-btn class="mr-7" icon small v-on="on">
              <v-icon> $dots-vertical</v-icon>
            </v-btn>
            {{ $trans("selected_n_positions", { amount: selected.length }) }}
          </template>
          <v-list>
            <v-menu right offset-x min-width="0">
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-2"> $status </v-icon>
                    {{ $trans("change_status") }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <v-list>
                <v-list-item link @click="updateSelected('approved')">
                  <v-list-item-title class="text-center">
                    <v-chip link color="green-light" class="green--text">
                      {{ $trans("approved") }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link @click="updateSelected('payment')">
                  <v-list-item-title class="text-center">
                    <v-chip link color="black--text" class="gray">
                      {{ $trans("payment") }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link @click="updateSelected('cancelled')">
                  <v-list-item-title class="text-center">
                    <v-chip link color="red-light" class="red--text">
                      {{ $trans("cancelled") }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link @click="updateSelected('done')">
                  <v-list-item-title class="text-center">
                    <v-chip link color="blue-light" class="blue--text">
                      {{ $trans("done") }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-list-item
              v-if="loggedUserCanDeleteBookings"
              link
              @click="removeSelected"
            >
              <v-list-item-title class="error--text d-flex align-center">
                <v-icon color="error" class="mr-2"> $trash-default </v-icon>
                {{ $trans("remove_n", { n: selected.length }) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="sendNotificationsToCustomers">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $send </v-icon>
                {{ $trans("send_customers_notification_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="sendNotificationsToEmployees">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $send </v-icon>
                {{ $trans("send_employees_notification_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('xlsx')">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_xlsx_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('xls')">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_xls_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('ods')">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_ods_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('html')">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_html_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('csv')">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_bookings_csv_title") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile('csv', true)">
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-2"> $download </v-icon>
                {{ $trans("download_unavailable_booking_slots_csv_title") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-progress-circular
          v-if="isFetchingFile"
          indeterminate
          color="primary"
          size="24"
          class="ml-4"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { downloadFile } from "@/lib/calendesk-js-library/tools/downloadFile";
import planActions from "@/calendesk/mixins/planActions";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";

export default {
  name: "SelectedEventsFooterMenu",
  mixins: [planActions, eventActions],
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFetchingFile: false,
    };
  },
  methods: {
    ...mapActions({
      fetchFile: "booking/fetchFile",
      fetchUnavailableBookingSlotFile:
        "booking/fetchUnavailableBookingSlotFile",
    }),
    removeSelected() {
      this.openConfirmDialog({
        type: confirmDialogTypes.REMOVE_EVENTS,
        data: {
          events: this.selected,
          skipNotifications: false,
        },
      });
    },
    updateSelected(status) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("change_status"),
        confirmAction: () =>
          this.updateBookingStatusForMultiple(this.selected, status),
      });
    },
    sendNotificationsToCustomers() {
      const parsedEvents = this.selected.map((selected) => selected.item);
      this.sendNotificationForEvents(
        parsedEvents,
        this.$helpers.customerNotificationCustomerType.user
      );
    },
    sendNotificationsToEmployees() {
      const parsedEvents = this.selected.map((selected) => selected.item);
      this.sendNotificationForEvents(
        parsedEvents,
        this.$helpers.customerNotificationCustomerType.employee
      );
    },
    downloadFile(type, onlyUnavailableBookingSlots = false) {
      pushEvent("downloadEventsFile_" + type);

      const bookingIds = this.selected
        .filter((obj) => obj.type === this.eventTypes.booking)
        .map((obj) => obj.id);

      const unavailableBookingSlotIds = this.selected
        .filter((obj) => obj.type === this.eventTypes.unavailableBookingSlot)
        .map((obj) => obj.id);

      this.isFetchingFile = true;

      let action = this.fetchFile;

      let payload = {
        booking_ids: bookingIds,
        unavailable_booking_slot_ids: unavailableBookingSlotIds,
        type: type,
      };

      if (onlyUnavailableBookingSlots) {
        action = this.fetchUnavailableBookingSlotFile;

        payload = {
          unavailable_booking_slot_ids: unavailableBookingSlotIds,
          type: type,
        };
      }

      action(payload)
        .then((file) => {
          downloadFile(file, type, this.$trans("events").toLowerCase(), true);
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isFetchingFile = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-booking-menu {
  position: absolute;
  bottom: 64px;
}
</style>
