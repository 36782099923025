import { displayCalendarDate } from "@/calendesk/tools/calendarHelpers";
import itemRowActions from "@/calendesk/mixins/itemRowActions";

export default {
  mixins: [itemRowActions],
  data() {
    return {
      eventTypes: this.$helpers.eventTypes,
    };
  },
  methods: {
    calendarDate(item) {
      return displayCalendarDate(item, true);
    },
    getPaidAmount(item) {
      let amount = 0;

      if (item.payment_transaction && item.payment_transaction.price) {
        amount += item.payment_transaction.price;
      }

      if (
        item.simple_store_product_payment_transactions &&
        item.simple_store_product_payment_transactions.length
      ) {
        item.simple_store_product_payment_transactions.forEach(
          (transaction) => {
            amount += transaction.price;
          }
        );
      }

      return amount;
    },
  },
};
