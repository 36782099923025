var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
    unavailable_booking_slot_details:
      _vm.item.event_type === _vm.eventTypes.unavailableBookingSlot,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(
          _vm.item.event_type === _vm.eventTypes.booking ||
          _vm.item.event_type === _vm.eventTypes.unavailableBookingSlot
        )?_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}}):(_vm.item.event_type === _vm.eventTypes.groupBooking)?_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isGroupSelected,"indeterminate":_vm.isGroupIndeterminate,"color":"primary"},on:{"input":function($event){return _vm.toggleGroupSelection(_vm.item)}}}):_vm._e()],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
      'v-align-middle': _vm.item.event_type === _vm.eventTypes.groupBooking,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[(_vm.item.event_type !== _vm.eventTypes.groupBooking)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$trans("group_booking_title"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.event_data.id)?_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.event_data.id))])]):(
          _vm.$vuetify.breakpoint.mdAndUp &&
          _vm.item.event_type === _vm.eventTypes.groupBooking
        )?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"blue-light","fab":"","x-small":"","elevation":"0","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickRow.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.isExpanded ? "$chevron-up" : "$chevron-down")+" ")])],1)]}}])},[(_vm.isExpanded)?_c('span',[_vm._v(_vm._s(_vm.$trans("collapse")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("expand")))])])],1):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("date"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.calendarDate(_vm.item))}})])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("event_type"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(
          (_vm.item.event_type === _vm.eventTypes.booking ||
            _vm.item.event_type === _vm.eventTypes.groupBooking) &&
          _vm.item.event_data.service_id
        )?_c('div',[_c('event-service',{attrs:{"hide-duration":"","clickable":"","push-transition":"","justify-left":_vm.$vuetify.breakpoint.mdAndUp,"justify-right":!_vm.$vuetify.breakpoint.mdAndUp,"service-id":_vm.item.event_data.service_id,"service-type-id":_vm.item.event_data.service_type_id
              ? _vm.item.event_data.service_type_id
              : null,"products":_vm.item.event_data.simple_store_products &&
            _vm.item.event_data.simple_store_products.length > 0
              ? _vm.item.event_data.simple_store_products
              : []}}),(_vm.item.event_type === _vm.eventTypes.booking)?_c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap py-2",class:{
              'justify-start': _vm.$vuetify.breakpoint.mdAndUp,
              'justify-end': !_vm.$vuetify.breakpoint.mdAndUp,
            }},[_c('event-status-chips',{class:{
                'mr-1': _vm.item.event_data.payment_transaction,
              },attrs:{"parsed-event":_vm.item.event_data}}),(_vm.item.event_data.payment_transaction)?_c('small',[_vm._v(" ("+_vm._s(_vm._f("money")(_vm.getPaidAmount(_vm.item.event_data)))+", "+_vm._s(_vm.$trans(_vm.item.event_data.payment_transaction.provider_tag))+")")]):_vm._e()],1)]):_vm._e()],1):_vm._e(),(_vm.item.event_type === _vm.eventTypes.unavailableBookingSlot)?_c('div',{staticClass:"d-flex"},[_c('event-source-type',{staticClass:"mr-2",attrs:{"item":_vm.item}}),(_vm.item.event_data && _vm.item.event_data.description)?_c('span',{staticClass:"text-wrap text-break"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.item.event_data.description,300)))]):_c('span',[_vm._v(_vm._s(_vm.$trans("blackout_date_title")))])],1):_vm._e()])]),(
      _vm.$vuetify.breakpoint.mdAndUp ||
      (_vm.item.event_type !== _vm.eventTypes.groupBooking &&
        _vm.item.event_type !== _vm.eventTypes.unavailableBookingSlot)
    )?_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("client"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.event_type === _vm.eventTypes.booking && _vm.item.event_data.user)?_c('div',[_c('user-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","user":_vm.item.event_data.user}})],1):_vm._e()])]):_vm._e(),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("employee"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(
          _vm.item.event_data.employee_id ||
          _vm.item.event_type === _vm.eventTypes.unavailableBookingSlot
        )?_c('div',{staticClass:"employee-details"},[_c('div',{staticClass:"mb-2"},[(_vm.item.event_data.employee_id)?_c('employee-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","employee-id":_vm.item.event_data.employee_id}}):(_vm.item.event_type === _vm.eventTypes.unavailableBookingSlot)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("all_employees"))+" ")]):_vm._e()],1)]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('event-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"item":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }