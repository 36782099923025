var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSending)?_c('div',{staticClass:"mb-16"},[(_vm.bookingsExist || _vm.unavailableBookingSlotsExist)?[_c('event-filter',{attrs:{"filtered-user":_vm.filteredUser},on:{"filtersChanged":_vm.filtersChanged,"filtersRestored":_vm.filtersRestored}}),_c('div',[_c('v-data-table',{staticClass:"calendesk-datatable",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoadingTable,"loading-text":_vm.$trans('loading'),"no-data-text":_vm.$trans('nothing_found_here'),"no-results-text":_vm.$trans('nothing_found_here'),"options":_vm.tableOptions,"server-items-length":_vm.total,"show-select":!_vm.noData,"expanded":_vm.$vuetify.breakpoint.mdAndUp ? _vm.expanded : [],"mobile-breakpoint":"960"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":_vm.isIndeterminateForSelectAll,"color":"primary"},on:{"input":_vm.selectAllItemsOnChange},model:{value:(_vm.selectAllState),callback:function ($$v) {_vm.selectAllState=$$v},expression:"selectAllState"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.event_data && item.event_data.bookings)?_vm._l((item.event_data.bookings),function(booking,index){return _c('expanded-event-item-row',{key:'booking_details_' + booking.id,attrs:{"index":index,"booking":booking,"item":item,"is-selected":_vm.isRowSelected({
                  id: booking.id,
                  type: _vm.eventTypes.booking,
                })},on:{"reload":_vm.loadEventsDebounce,"selectOnChange":_vm.itemRowEventOnSelectChange,"clickRow":_vm.clickRow}})}):_vm._e()]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('event-item-row',{attrs:{"is-expanded":_vm.isExpanded(item),"item":item,"is-group-indeterminate":_vm.isGroupIndeterminate(item),"is-group-selected":_vm.isGroupSelected(item),"is-selected":_vm.isRowSelected({
                id: item.event_data.id,
                type: item.event_type,
              })},on:{"deselectGroupBookings":_vm.deselectGroupBookings,"selectGroupBookings":_vm.selectGroupBookings,"selectOnChange":_vm.itemRowEventOnSelectChange,"clickRow":_vm.clickRow}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('selected-events-footer-menu',{attrs:{"selected":_vm.selected},on:{"update":_vm.handleSelectedBookingsUpdate}})],1)]:_c('no-data-info',{attrs:{"button-text":_vm.$trans('add_booking'),"description":_vm.$trans('no_bookings_info')},on:{"click":_vm.handleNoDataClick}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }