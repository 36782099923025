<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersEventDateRange"
          :label="$trans('event_date_range')"
          clearable
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreateDateRange"
          only-past-dates
          :label="$trans('created_date_range')"
          clearable
          dense
          :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedEventTypes"
          :items="eventTypesForSelect"
          :label="$trans('event_type')"
          multiple
          small-chips
          item-text="name"
          item-value="id"
          outlined
          hide-details
          clearable
          deletable-chips
          dense
          :disabled="disabled"
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.name
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatuses"
          :items="statuses"
          :label="$trans('status')"
          deletable-chips
          dense
          hide-details="auto"
          multiple
          outlined
          small-chips
          :menu-props="{ offsetY: true }"
          clearable
          :disabled="disabled || requestedOnlyUnavailableBookingSlotEvent"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersPayment"
          :items="paymentTypes"
          :label="$trans('payment_2')"
          clearable
          dense
          hide-details="auto"
          outlined
          :disabled="disabled || requestedOnlyUnavailableBookingSlotEvent"
          :menu-props="{ offsetY: true }"
          small-chips
          deletable-chips
        />
      </v-col>
      <v-col cols="12" sm="3">
        <tags-field
          v-model="selectedFiltersTags"
          hide-action-buttons
          :disabled="disabled || requestedOnlyUnavailableBookingSlotEvent"
          dense
          small-chips
          deletable-chips
          multiple
        />
      </v-col>
      <v-col cols="12" sm="3">
        <clients-field
          v-model="selectedFiltersUsers"
          hide-actions
          :multiple="!filteredUser"
          small-chips
          deletable-chips
          dense
          :disabled="
            disabled ||
            requestedOnlyUnavailableBookingSlotEvent ||
            !!filteredUser
          "
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <services-field-2
          v-model="selectedFiltersServices"
          dense
          :disabled="disabled || requestedOnlyUnavailableBookingSlotEvent"
          small-chips
          multiple-selection
          deletable-chips
          keep-menu-open-on-select
          allow-category-selection
        />
      </v-col>
      <v-col v-if="loggedUserCanSeeAllBookings" cols="12" sm="3">
        <employee-field
          v-model="selectedFiltersEmployees"
          :label="$trans('employee')"
          dense
          :disabled="disabled"
          small-chips
          multiple
          deletable-chips
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" :sm="loggedUserCanSeeAllBookings ? 3 : 6">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex justify-center align-center" cols="12" md="3" sm="6">
        <v-btn
          :disabled="disabled || isTodayFilterEnabled"
          color="primary"
          outlined
          small
          @click="setToday"
        >
          {{ $trans("bookings_today") }}
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center align-center" cols="12" md="3" sm="6">
        <v-btn
          :disabled="disabled || isTomorrowFilterEnabled"
          color="primary"
          outlined
          small
          @click="setTomorrow"
        >
          {{ $trans("bookings_tomorrow") }}
        </v-btn>
      </v-col>
      <v-col
        v-if="loggedUserEmployee && loggedUserCanSeeAllBookings"
        class="d-flex justify-center align-center"
        cols="12"
        md="3"
        sm="6"
      >
        <v-btn
          :disabled="disabled"
          color="primary"
          outlined
          small
          @click="setCurrentEmployee"
        >
          {{ $trans("my_booking") }}
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TagsField from "@/components/Forms/TagsField.vue";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";
import { mapGetters } from "vuex";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import ServicesField2 from "@/lib/calendesk-js-library/components/ServicesField2.vue";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "EventFilter",
  components: {
    CalendarRangeInput,
    ServicesField2,
    ClientsField,
    EmployeeField,
    TagsField,
  },
  mixins: [roleActions, sharedListFilters, sharedActions],
  props: {
    filteredUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cacheId: this.filteredUser ? null : "event-list-filters", // cache for filtered users should not store the user
      statuses: bookingStatuses.inputValues(),
      selectedEventTypes: [],
      selectedFiltersPayment: null,
      selectedFiltersServices: null,
      selectedFiltersEmployees: null,
      selectedFiltersUsers: this.filteredUser,
      selectedFiltersTags: [],
      selectedFiltersStatuses: [],
      selectedFiltersSearch: null,
      selectedFiltersEventDateRange: [],
      selectedFiltersCreateDateRange: [],
      paymentTypes: [
        {
          text: trans("paid"),
          value: 1,
        },
        {
          text: trans("not_paid"),
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tags: "tag/tags",
      getServices: "category/getServices",
      employees: "employee/getAllEmployees",
      categoryWithServices: "category/categoryWithServices",
    }),
    eventTypesForSelect() {
      const types = [
        {
          id: this.$helpers.eventTypes.booking,
          name: this.$trans("event_type_booking"),
        },
        {
          id: this.$helpers.eventTypes.groupBooking,
          name: this.$trans("event_type_group_booking"),
        },
      ];

      if (!this.filteredUser) {
        types.push({
          id: this.$helpers.eventTypes.unavailableBookingSlot,
          name: this.$trans("event_type_unavailable_booking_slot"),
        });
      }

      return types;
    },
    isTodayFilterEnabled() {
      return (
        this.selectedFiltersEventDateRange &&
        this.selectedFiltersEventDateRange.length === 2 &&
        this.selectedFiltersEventDateRange[0] === this.getToday() &&
        this.selectedFiltersEventDateRange[1] === this.getToday()
      );
    },
    isTomorrowFilterEnabled() {
      return (
        this.selectedFiltersEventDateRange &&
        this.selectedFiltersEventDateRange.length === 2 &&
        this.selectedFiltersEventDateRange[0] === this.getTomorrow() &&
        this.selectedFiltersEventDateRange[1] === this.getTomorrow()
      );
    },
    filters() {
      return {
        selectedFiltersTags: this.selectedFiltersTags,
        selectedFiltersStatuses: this.selectedFiltersStatuses,
        selectedFiltersPayment: this.selectedFiltersPayment,
        selectedFiltersServices: this.selectedFiltersServices,
        selectedFiltersEmployees: this.selectedFiltersEmployees,
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedEventTypes: this.selectedEventTypes,
        selectedFiltersUsers: this.selectedFiltersUsers,
        selectedFiltersCreateDateRange: this.selectedFiltersCreateDateRange,
        selectedFiltersEventDateRange: this.selectedFiltersEventDateRange,
      };
    },
    requestedOnlyUnavailableBookingSlotEvent() {
      return (
        this.selectedEventTypes &&
        this.selectedEventTypes.length === 1 &&
        this.selectedEventTypes[0] ===
          this.$helpers.eventTypes.unavailableBookingSlot
      );
    },
  },
  watch: {
    requestedOnlyUnavailableBookingSlotEvent(val) {
      if (val) {
        this.selectedFiltersPayment = null;
        this.selectedFiltersServices = null;
        this.selectedFiltersUsers = null;
        this.selectedFiltersStatuses = [];
        this.selectedFiltersTags = [];
      }
    },
  },
  mounted() {
    if (this.$route.query.period) {
      if (this.$route.query.period === "today") {
        this.setToday();
      }

      if (this.$route.query.period === "week") {
        this.setThisWeek();
      }
    }

    if (this.$route.query.query) {
      this.selectedFiltersSearch = this.$route.query.query;
    }
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersTags && this.selectedFiltersTags.length > 0) {
        data.tag_ids = this.selectedFiltersTags.join(",");
      }

      if (
        this.selectedFiltersStatuses &&
        this.selectedFiltersStatuses.length > 0
      ) {
        data.status_ids = this.selectedFiltersStatuses.join(",");
      }

      if (
        this.selectedFiltersEmployees &&
        this.selectedFiltersEmployees.length > 0
      ) {
        data.employee_ids = this.selectedFiltersEmployees
          .filter((e) => e && e.id)
          .map((e) => e.id)
          .join(",");
      }

      if (this.selectedFiltersUsers && this.selectedFiltersUsers.length > 0) {
        data.user_ids = this.selectedFiltersUsers.map((u) => u.id).join(",");
      } else if (this.selectedFiltersUsers && this.selectedFiltersUsers.id) {
        data.user_ids = this.selectedFiltersUsers.id;
      }

      if (this.selectedFiltersPayment !== null) {
        data.paid = this.selectedFiltersPayment;
      }

      if (
        this.selectedFiltersEventDateRange &&
        this.selectedFiltersEventDateRange.length > 0
      ) {
        data.event_date_from = this.selectedFiltersEventDateRange[0];

        if (this.selectedFiltersEventDateRange.length === 2) {
          data.event_date_to = this.selectedFiltersEventDateRange[1];
        } else {
          data.event_date_to = data.event_date_from;
        }
      }

      if (
        this.selectedFiltersCreateDateRange &&
        this.selectedFiltersCreateDateRange.length > 0
      ) {
        data.create_date_from = this.selectedFiltersCreateDateRange[0];

        if (this.selectedFiltersCreateDateRange.length === 2) {
          data.create_date_to = this.selectedFiltersCreateDateRange[1];
        } else {
          data.create_date_to = data.create_date_from;
        }
      }

      if (
        this.selectedFiltersServices &&
        this.selectedFiltersServices.length > 0
      ) {
        const serviceIds = this.selectedFiltersServices
          .filter((u) => u.service_id && !u.service_type_id)
          .map((u) => u.service_id);

        if (serviceIds.length > 0) {
          data.service_ids = serviceIds.join(",");
        }

        const serviceTypeIds = this.selectedFiltersServices
          .filter((u) => u.service_type_id)
          .map((u) => u.service_type_id);

        if (serviceTypeIds.length > 0) {
          data.service_type_ids = serviceTypeIds.join(",");
        }

        const categoryIds = this.selectedFiltersServices
          .filter((u) => u.is_category)
          .map((u) => u.category_id);

        if (categoryIds.length > 0) {
          data.category_ids = categoryIds.join(",");
        }
      }

      if (this.selectedEventTypes && this.selectedEventTypes.length > 0) {
        data.event_types = this.selectedEventTypes.join(",");
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
    setToday() {
      this.selectedFiltersEventDateRange = [this.getToday(), this.getToday()];
    },
    setTomorrow() {
      this.selectedFiltersEventDateRange = [
        this.getTomorrow(),
        this.getTomorrow(),
      ];
    },
    setThisWeek() {
      this.selectedFiltersEventDateRange = [
        this.$moment().startOf("week").format(this.$helpers.dateFormat),
        this.$moment().endOf("week").format(this.$helpers.dateFormat),
      ];
    },
    setCurrentEmployee() {
      if (this.loggedUserEmployee && this.employees) {
        this.selectedFiltersEmployees = [
          this.employees.find(
            (employee) => this.loggedUserEmployee.id === employee.id,
          ),
        ];
      }
    },
    clearFilters() {
      for (let filter in this.filters) {
        if (this.filteredUser && filter === "selectedFiltersUsers") {
          continue;
        }
        this[filter] = Array.isArray(this[filter]) ? [] : null;
      }

      this.filtersChanged();
    },
  },
};
</script>
