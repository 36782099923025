<template>
  <v-row justify="center">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <div id="add-tag-activator" class="outlined-icon ml-3 mt-3" v-on="on">
          <v-icon>$plus</v-icon>
        </div>
      </template>
      {{ $trans("add") }}
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      transition="scale-transition"
      max-width="434px"
      persistent
      activator="#add-tag-activator"
    >
      <v-card :loading="createTagLoader">
        <v-card-title>
          {{ $trans("add_tag") }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2">
          <v-container>
            <v-form ref="form" @submit.prevent="handleSave">
              <v-text-field
                v-model="name"
                :rules="[rules.required]"
                :label="$trans('tag')"
                :disabled="createTagLoader"
                outlined
              />

              <v-card-actions class="pa-0">
                <v-spacer />
                <v-btn text :disabled="createTagLoader" @click="closeModal">
                  {{ $trans("cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  type="submit"
                  :disabled="createTagLoader"
                >
                  {{ $trans("add") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AddTagModal",
  mixins: [sharedActions],
  data: () => ({
    dialog: false,
    name: null,
    rules: {
      required,
    },
    createTagLoader: false,
  }),
  methods: {
    ...mapActions({
      createTag: "tag/create",
      setNewTag: "tag/setNewTag",
      refreshTags: "tag/refreshTags",
      fetchAllTags: "tag/fetchAll",
    }),
    closeModal() {
      this.dialog = false;
    },
    async handleSave() {
      if (this.$refs.form.validate()) {
        this.createTagLoader = true;

        pushEvent("createTag");

        this.createTag(this.name)
          .then(async (tag) => {
            await this.fetchAllTags();
            await this.refreshTags();
            await this.setNewTag(tag);
            successNotification("added_tag");
          })
          .catch((error) => {
            errorNotification(null, error);
          })
          .finally(() => {
            this.createTagLoader = false;
            this.closeModal();
          });
      }
    },
  },
};
</script>
