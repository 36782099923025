<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryButtons"
    @fireAction="fire"
  />
</template>
<script>
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";
import ListButtons from "@/components/ListButtons.vue";

export default {
  name: "EventListButtons",
  components: { ListButtons },
  mixins: [eventActions],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      actions.push({
        action: "preview",
        color: "light",
        icon: "$magnify",
        title: this.$trans("preview"),
      });

      actions.push({
        action: "edit",
        color: "light",
        icon: "$pencil",
        title: this.$trans("edit"),
      });

      if (this.canItemHandlePayments(this.item)) {
        actions.push({
          action: this.item.event_data.paid ? "payments" : "pay",
          color: "light",
          icon: "$cash",
          title: this.item.event_data.paid
            ? this.$trans("user_payments")
            : this.$trans("pay"),
        });
      }

      if (this.canEventBeDuplicated(this.item)) {
        actions.push({
          action: "duplicate",
          color: "light",
          icon: "$copy",
          title: this.isGroupBooking(this.item)
            ? this.$trans("duplicate_group_bookings_title")
            : this.$trans("duplicate"),
        });
      }

      if (
        this.canItemSendNotificationsToCustomers(this.item) &&
        this.loggedUserCanAddNotifications
      ) {
        actions.push({
          action: "send_notification_to_customers",
          color: "light",
          icon: "$send",
          title: this.isGroupBooking(this.item)
            ? this.$trans("send_customers_notification_title")
            : this.$trans("send_customer_notification_title"),
        });
      }

      if (
        this.canItemSendNotificationsToEmployees(this.item) &&
        this.loggedUserCanAddNotifications
      ) {
        actions.push({
          action: "send_notification_to_employees",
          color: "light",
          icon: "$send",
          title: this.$trans("send_employee_notification_title"),
        });
      }

      if (this.canCancelItem(this.item)) {
        actions.push({
          action: "cancel",
          color: "error",
          icon: "$close",
          title: this.isGroupBooking(this.item)
            ? this.$trans("cancel_group_bookings_title")
            : this.$trans("cancel"),
        });
      }

      if (this.canDeleteItem(this.item)) {
        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.isGroupBooking(this.item)
            ? this.$trans("delete_group_bookings_title")
            : this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryButtons() {
      const actions = [
        {
          action: "preview",
          color: "light",
          icon: "$magnify",
          title: this.$trans("preview"),
        },
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];

      if (this.canItemHandlePayments(this.item)) {
        actions.push({
          action: this.item.event_data.paid ? "payments" : "pay",
          color: "light",
          icon: "$cash",
          title: this.item.event_data.paid
            ? this.$trans("user_payments")
            : this.$trans("pay"),
        });
      }

      return actions;
    },
    getItem() {
      return this.$helpers.cloneObject(this.item);
    },
  },
  methods: {
    fire(action) {
      this.fireAction(action, this.getParsedEvent(this.getItem));
    },
  },
};
</script>
